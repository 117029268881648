










import Vue from 'vue';
import Component from 'vue-class-component';
import Navbar from '@/components/Navbar.vue';
import { Address } from '../models/models';
import { search } from '@/util/simpleSearch';
import { Prop } from 'vue-property-decorator';
import { getAddresses } from '../api/api';

@Component({
  components: {
    Navbar,
  },
})
export default class AddressesPage extends Vue {
  addresses: Address[] = [];

  mounted() {
    getAddresses().then(res => (this.addresses = res));
  }
}
